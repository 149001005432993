<template>
  <div>
    <v-data-table
      :headers="tableColumns"
      :items="appointments"
      :options.sync="tableOptions"
      :server-items-length="totalAppointments"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- user -->
      <template #[`item.participant`]="{item}">
        <template v-if="item.group.participant">
          <span class="mr-2">{{ item.group.participant.last_name }}</span>
          <span>{{ item.group.participant.first_name }}</span>
        </template>
      </template>

      <template #[`item.participant_contact`]="{item}">
        <template v-if="item.group.participant">
          <div>{{ item.group.participant.email }}</div>
          <div>{{ item.group.participant.phone }}</div>
        </template>
      </template>

      <template #[`item.user_id`]="{item}">
        <template v-if="item.user">
          <div>{{ item.user.email }}</div>
          <div>{{ item.user.phone }}</div>
        </template>
      </template>

      <!-- location - just first -->
      <template #[`item.destination_id`]="{item}">
        <div
          v-for="(application, index) in item.applications"
          v-show="index === 0"
          v-if="application.destination"
          :key="application.id"
          class="mb-1"
        >
          <div><b>{{ t(application.destination.name) }}</b></div>
          <div class="text-wrap-pretty min-w-400">
            {{ t(application.destination.address) }}
          </div>
        </div>
      </template>

      <!-- service -->
      <template #[`item.rechargeable_id`]="{item}">
        <div class="text-wrap-pretty min-w-200">
          {{ item.rechargeable.name.en }}
        </div>
      </template>

      <!-- service datetime -->
      <template #[`item.applications`]="{item}">
        <div v-if="['pending', 'wait_for_amendment'].includes(item.status)">
          <div
            v-for="(application, index) in getPendingApplicatoins(item.applications)"
            v-if="application.inventory_snapshot && application.inventory_snapshot.info"
            :key="application.id"
            class="mb-1"
          >
            ({{ index + 1 | ordinalNumber }}) {{ application.inventory_snapshot.info.started_at | date }}
          </div>
        </div>
        <div v-else-if="item.status === 'confirmed'">
          {{ getConfirmedApplicationDate(item.applications) | date }}
        </div>
      </template>

      <!-- action -->
      <template #[`item.actions`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="canReschedule(item)"
              @click="rescheduleAppointment(item.id)"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiCalendarArrowRight }}
                </v-icon>

                <span>Reschedule</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="canConfirm(item)"
              @click="confirmAppointment(item.id)"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
                <span>Confirm</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="canCancel(item)"
              @click="cancelAppointment(item.id)"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiCancel }}
                </v-icon>
                <span>Cancel</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="canCheckIn(item)"
              @click="checkInAppointment(item.id)"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiMapMarkerCheckOutline }}
                </v-icon>
                <span>Check-in</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-model="isDialogVisible"
      class="v-dialog-sm"
      width="700"
    >
      <reschedule-dialog
        v-if="dialog === 'reschedule'"
        :appointment="currentAppointment"
        @rescheduled="onAppointmentRescheduled"
      />
      <confirm-dialog
        v-if="dialog === 'confirm'"
        :appointment="currentAppointment"
        @confirmed="onAppointmentConfirmed"
      />
      <cancel-dialog
        v-if="dialog === 'cancel'"
        :appointment="currentAppointment"
        @cancelled="onAppointmentCancelled"
      />
      <check-in-dialog
        v-if="dialog === 'check-in'"
        :appointment="currentAppointment"
        @checkedIn="onAppointmentCheckedIn"
      />
    </v-dialog>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { ordinalNumber, price } from '@core/utils/filter'
import {
  mdiCalendarArrowRight,
  mdiCancel,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiMapMarkerCheckOutline,
  mdiPencil,
  mdiPlus,
  mdiReceipt,
  mdiTrendingUp,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'
import useTable from '../../composables/appointment/useTable'
import CancelDialog from './CancelDialog'
import CheckInDialog from './CheckInDialog'
import ConfirmDialog from './ConfirmDialog'
import RescheduleDialog from './RescheduleDialog'

export default {
  filters: {
    price,
    date: value => {
      if (!value) return

      return dayjs(value).format('YYYY-MM-DD HH:mm')
    },
    ordinalNumber,
  },
  components: {
    RescheduleDialog,
    ConfirmDialog,
    CancelDialog,
    CheckInDialog,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    appointments: {
      type: Array,
      required: true,
      default: [],
    },
    totalAppointments: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { tableColumns, tableOptions } = useTable()

    const rescheduleDialogRef = ref(null)
    const confirmDialogRef = ref(null)
    const cancelDialogRef = ref(null)
    const checkInDialogRef = ref(null)
    const isDialogVisible = ref(false)
    const dialog = ref('')

    const currentAppointment = ref({})

    const getPendingApplicatoins = applications => applications.filter(application => application.status === 'pending')

    const getConfirmedApplicationDate = applications => {
      const application = applications.find(application => application.status === 'accepted')

      if (application && application.inventory_snapshot) {
        return application.inventory_snapshot.info.started_at
      }

      return null
    }

    const setDialog = (d, id) => {
      currentAppointment.value = props.appointments.find(appointment => appointment.id === id)
      dialog.value = d
      isDialogVisible.value = true
    }

    const rescheduleAppointment = id => setDialog('reschedule', id)

    const confirmAppointment = id => setDialog('confirm', id)

    const cancelAppointment = id => setDialog('cancel', id)

    const checkInAppointment = id => setDialog('check-in', id)

    const refreshAndClose = () => {
      emit('refresh')
      isDialogVisible.value = false
    }

    const onAppointmentRescheduled = refreshAndClose

    const onAppointmentConfirmed = refreshAndClose

    const onAppointmentCancelled = refreshAndClose

    const onAppointmentCheckedIn = refreshAndClose

    const canReschedule = application => ['pending', 'confirmed', 'wait_for_amendment'].includes(application.status)

    const canConfirm = application => ['pending', 'wait_for_amendment'].includes(application.status)

    const canCancel = application => ['pending', 'confirmed', 'wait_for_amendment'].includes(application.status)

    const canCheckIn = application => ['confirmed'].includes(application.status)

    watch(
      () => tableOptions.value,
      () => {
        emit('optionChanged', tableOptions.value)
      },
    )

    return {
      t,
      tableColumns,
      tableOptions,

      rescheduleDialogRef,
      confirmDialogRef,
      cancelDialogRef,
      checkInDialogRef,

      currentAppointment,

      onAppointmentRescheduled,
      onAppointmentConfirmed,
      onAppointmentCancelled,
      onAppointmentCheckedIn,

      getPendingApplicatoins,
      getConfirmedApplicationDate,

      rescheduleAppointment,
      confirmAppointment,
      cancelAppointment,
      checkInAppointment,

      canReschedule,
      canConfirm,
      canCancel,
      canCheckIn,

      isDialogVisible,
      dialog,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiReceipt,
        mdiPencil,
        mdiMapMarkerCheckOutline,
        mdiCalendarArrowRight,
        mdiCancel,
      },
    }
  },
}
</script>
../../composables/appointment/useTable
