<template>
  <div>
    <v-card>
      <v-card-title class="font-weight-semibold">
        Reschedule appointment
      </v-card-title>

      <v-card-text>
        <router-link :to="{ name: 'product-view', params: { id : appointment.group.groupable_id } }">
          Service Slot Product
        </router-link>

        <p>Select Date:</p>
        <v-date-picker
          v-model="selectedDate"
          color="primary"
          :min="minDate"
        ></v-date-picker>

        <p>Select Time Slot:</p>
        <v-autocomplete
          v-model="selectedTimeSlot"
          :items="timeSlots"
          item-text="text"
          item-value="value"
          placeholder="Select Time Slot"
          :loading="loading"
          :disabled="loading"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          type="button"
          class="mr-4"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="rescheduleAppointment"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { appointmentService, userQuotaService } from '@/modules/product/services'
import { ref, watchEffect } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const selectedDate = ref(null)
    const selectedTimeSlot = ref(null)
    const timeSlots = ref([])

    const minDate = dayjs().format('YYYY-MM-DD')
    selectedDate.value = minDate

    const reset = () => {
      loading.value = false
      selectedDate.value = minDate
      selectedTimeSlot.value = null
      timeSlots.value = []
    }

    const getSlotTimeRange = slot => (slot ? `${dayjs(slot.started_at).format('HH:mm')} - ${dayjs(slot.ended_at).format('HH:mm')}` : null)

    const listTimeSlots = async () => {
      if (props.appointment.rechargeable) {
        const { records } = await userQuotaService.listTimeSlots(props.appointment.id, {
          started_at_from: dayjs(`${selectedDate.value} 00:00:00`).format(),
          ended_at_to: dayjs(`${selectedDate.value} 23:59:59`).format(),
        })

        timeSlots.value = records.map(record => ({
          text: getSlotTimeRange(record),
          value: record.id,
        }))
      }
    }

    const toggleDialog = status => {
      isDialogVisible.value = status
    }

    const rescheduleAppointment = async () => {
      loading.value = true

      const firstApplication = props.appointment.applications.find(a => a.status === 'pending') || props.appointment.applications[0]

      const { data } = await appointmentService.reschedule(firstApplication.id, {
        inventory_id: selectedTimeSlot.value,
      })

      toggleDialog(false)

      reset()

      emit('rescheduled')
    }

    // watch selectedDate changed
    watchEffect(() => {
      if (selectedDate.value) {
        listTimeSlots()
      }
    })

    return {
      loading,
      isDialogVisible,

      minDate,
      selectedDate,
      selectedTimeSlot,

      timeSlots,

      toggleDialog,
      rescheduleAppointment,
    }
  },
}
</script>
